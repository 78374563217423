"use client";

import { useState } from "react";

import Image from "next/image";

import EnquiryWizard from "@/components/Enquiry/Enquiry";
import Button from "@/components/button/Button";
import Header from "@/components/header/Header";
import Heading from "@/components/heading/heading";

import Arrow from "@/public/svg/arrow-right.svg";
import Whatsapp from "@/public/svg/common/social-icons/whatsapp.svg";
import Successmark from "@/public/svg/success-icon.svg";

import "@/styles/common/typo.scss";
import "@/styles/common/indicator.scss";
import "@/styles/home/form.scss";
import "./hero-video.scss";

import { Link } from "react-scroll";

interface HeroProps {
    title: string;
    description: string;
    poster: string;
    video: string;
}

const Hero = (props: HeroProps) => {
    const { video, poster } = props;
    const [formSubmitStatus, setFormSubmitStatus] = useState(false);

    return (
        <div className="hero-section" id="hero">
            <div className="video-wrapper">
                <video
                    className="background-video flex"
                    autoPlay
                    muted
                    loop
                    playsInline
                    preload="none"
                    poster={poster}
                >
                    <source src={video} type="video/mp4" />
                </video>
            </div>
            <Header />
            <div className="hero-inner container px-4 flex flex-col mx-auto">
                <div className="hero-content my-auto grid grid-cols-1 gap-10 lg:gap-4 lg:grid-cols-5 xl:grid-cols-8">
                    <div className="col-span-1 flex flex-col lg:col-span-3 xl:col-span-5">
                        <div className="intro flex flex-col gap-4 my-auto">
                            <Heading as="h1">{props.title}</Heading>
                            <p className="lead text-white">
                                {props.description}
                            </p>
                            <div className="button-group flex flex-wrap items-center gap-3">
                                <Link
                                    to="popularDestinations"
                                    smooth={true}
                                    duration={1}
                                >
                                    <Button
                                        className="flex items-center gap-3"
                                        variant="orange"
                                    >
                                        <span>Explore Packages</span>

                                        <Image loading="lazy"
                                            src={Arrow}
                                            alt="Arrow"
                                            width={24}
                                            height={24}

                                        />
                                    </Button>
                                </Link>
                                {/* <Button
                                    className="flex items-center gap-3"
                                    variant="outline"
                                    onClick={() =>
                                        window.open(
                                            "https://api.whatsapp.com/send/?phone=919886883456&text=Hi%2C+I+need+your+help+to+plan+my+upcoming+trip.&type=phone_number&app_absent=0",
                                            "_blank"
                                        )
                                    }
                                >
                                    <Image loading="lazy"
                                        src={Whatsapp}
                                        alt="Whatsapp"
                                        width={24}
                                        height={24}
                                        
                                    />
                                    <span>Chat on WhatsApp</span>
                                </Button> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 lg:col-span-2 xl:col-span-3">
                        {formSubmitStatus ? (
                            <div className="enquire-wizard p-6 flex flex-col gap-6 justify-center items-center h-434 lg:h-456">
                                <Image loading="lazy"
                                    src={Successmark}
                                    alt="Successmark"
                                    width={68}
                                    height={68}

                                />
                                <h4>Thanks for Submitting the details</h4>
                                <p className="text-center">Check email for day-wise itinerary and estimated pricing. Our travel planner will contact you in 24hrs.</p>
                            </div>
                        ) : (
                            <div className="enquire-wizard p-6 flex flex-col gap-6">
                                <div className="form-title flex flex-col">
                                    <Heading as="h3" className="font-yesteryear font-extralight">
                                        Your Perfect Holiday Awaits!
                                    </Heading>
                                    <Heading as="h3" className="mt-1">
                                        Download Your Itinerary Now!
                                    </Heading>
                                </div>
                                <EnquiryWizard
                                    setFormSubmitStatus={setFormSubmitStatus}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
