import(/* webpackMode: "eager" */ "/app/components/button/button.scss");
;
import(/* webpackMode: "eager" */ "/app/components/heading/heading.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/public/png/common/enquiry/common-cta-footer.png");
;
import(/* webpackMode: "eager" */ "/app/public/svg/common/meta/customized-trip.svg");
;
import(/* webpackMode: "eager" */ "/app/public/svg/common/meta/destination-experts.svg");
;
import(/* webpackMode: "eager" */ "/app/public/svg/common/meta/google-ratings.svg");
;
import(/* webpackMode: "eager" */ "/app/public/svg/common/meta/transparent-pricing.svg");
;
import(/* webpackMode: "eager" */ "/app/public/svg/common/meta/trip-assistance.svg");
;
import(/* webpackMode: "eager" */ "/app/public/svg/common/social-icons/whatsapp.svg");
;
import(/* webpackMode: "eager" */ "/app/sections/common/enquiry/enquiry.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/common/footer/contact.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/common/happy-customers/happy-customers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/common/hero-video/hero-video.tsx");
;
import(/* webpackMode: "eager" */ "/app/sections/common/meta/meta.scss");
;
import(/* webpackMode: "eager" */ "/app/sections/common/footer/footer.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/common/view-more/view-more.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/home/budget-friendly/budget-friendly.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/home/hand-picked/hand-picked.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/home/meet-expert.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/home/packages-based/packages-based.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/home/popular-destinations/popular-destinations.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/home/trending-offers/trending-offer.tsx");
