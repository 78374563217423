import Image from "next/image";

import Button from "@/components/button/Button";
import Heading from "@/components/heading/heading";

import Airplane from "@/public/svg/destinations/hand-picked/airplane.svg";
import Building from "@/public/svg/destinations/hand-picked/building.svg";
import LocationIcon from "@/public/svg/destinations/hand-picked/location.svg";
import Ranking from "@/public/svg/destinations/hand-picked/ranking.svg";
import Buttonwhatsapp from "@/public/svg/common/social-icons/whatsapp.svg";

import "./tour-card.scss";

interface TourCardProps {
    name: string;
    picture: any;
    location: string;
    place: string;
    price: string;
    type: string;
    list: {
        icon: string;
        title: string;
    }[];
    mostbook: {
        title: string;
        color: string;
    }[];
}

const TourCard = ({h}:any) => {
    
    return (
        <div className="inline-flex flex-col tour-card card">
            <figure>
                <Image loading="lazy" src={h.picture} alt={h.name}  />
                <ul className="tag-list flex flex-wrap absolute top-4 items-center gap-3">
                            {h?.mostbook?.map((t:any, index:any) => (
                                <li
                                    className="flex tag-list-item rounded-e-lg py-2 px-3"
                                    key={index}
                                    style={{ backgroundColor: t.color }}
                                >
                                    <span className="font-medium color-white">
                                        {t.title}
                                    </span>
                                </li>
                            ))}
            </ul>
                <figcaption className="flex-center py-2 px-4 gap-2">
                    <span className="font-medium text-white">{h.type}</span>
                </figcaption>
            </figure>
            <div className="tour-card-info flex flex-col gap-2 sm:gap-4 p-4">
                <div className="tour-card-details flex flex-col gap-2 sm:gap-4">
                    <div className="tour-card-location flex items-center gap-2">
                        <div className="flex items-center gap-1">
                            <Image loading="lazy" src={LocationIcon} alt="location"  />
                            <span>{h.location}</span>
                        </div>
                        <span className="tour-card-place dot">{h.place}</span>
                    </div>
                    <Heading as="h6" className="">
                        {h.name}
                    </Heading>
                    <ul className="flex flex-wrap items-center gap-3.5">
                        {h.list.map((item:any, index:any) => (
                            <li
                                key={index}
                                className="option-list-item flex items-center gap-1"
                            >
                                <Image loading="lazy"
                                    src={item.icon}
                                    alt={item.title}
                                    
                                />
                                <span className="font-medium ">
                                    {item.title}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="tour-card-bottom flex items-center justify-between pt-2 sm:pt-4">
                    <div className="person flex flex-col gap-3">
                       
                        <strong>
                            {h.price}
                            <span>/Person</span>
                        </strong>
                    </div>
                   
                    
                </div>
                <div className="items-center justify-between">
                    <a href="https://wa.me/919600099777?text=Hi,%20I%20am%20Seeking%20Details%20on%20Tour%20Packages.">
                    <Button className="flex items-center justify-center gap-3 w-full text-center" variant="green">
                    <Image src={Buttonwhatsapp} alt="whatsappimage" />{" "}
                        <span>Book Now</span>
                    </Button>
                    </a>
                    </div>
            </div>
        </div>
    );
};

export default TourCard;
