"use client";

import { useState } from "react";

import Picture1 from "./images/handpicked-1/andaman-img-1.jpg";
import Picture2 from "./images/handpicked-1/andaman-img-2.jpg";
import Picture3 from "./images/handpicked-1/andaman-img-3.jpg";
import Picture4 from "./images/handpicked-1/andaman-img-4.jpg";
import Picture5 from "./images/handpicked-1/andaman-img-5.jpg";
import Picture6 from "./images/handpicked-1/andaman-img-6.jpg";
import Car from "@/public/svg/destinations/hand-picked/car.svg";
import Building from "@/public/svg/destinations/hand-picked/building.svg";
import Activity from "@/public/svg/destinations/hand-picked/activity.svg";
import PaginationLeft from "@/public/svg/paginarition-arrow-left.svg";
import PaginationRight from "@/public/svg/paginarition-arrow-right.svg";

import Image from "next/image";

import "@/styles/common/swiper.scss";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { Swiper as TSwiper } from "swiper/types";

import TredingCard from "@/components/trending-card/trending-card";
import Title from "@/components/title/Title";

import "@/sections/common/vertical-card/vertical-card.scss";

const Honeymoon = [
    {
        name: "2N Port Blair | 1N Havelock",
        picture: Picture1,
        location: "Andaman",
        place: "2 Places",
        price: "₹27,999",
        type: "4 Days",
        list: [
            {
                icon: Building,
                title: "4 Star Hotels",
            },
            {
                icon: Activity,
                title: "6 Activities",
            },
            {
                icon: Car,
                title: "Transfers",
            },
        ],
        mostbook: [
            {
                title: "Highly Popular",
                color: "#FF4514",
            },
        ],
    },
    {
        name: "3N Port Blair",
        picture: Picture2,
        location: "Andaman",
        place: "1 Place",
        price: "₹30,999",
        type: "4 Days",
        list: [
            {
                icon: Building,
                title: "4 Star Hotels",
            },
            {
                icon: Activity,
                title: "5 Activities",
            },
            {
                icon: Car,
                title: "Transfers",
            },
        ],
        mostbook: [
            {
                title: "Highly Popular",
                color: "#FF4514",
            },
        ],
    },
    {
        name: "2N Port Blair | 1N Havelock | 1N Neil",
        picture: Picture3,
        location: "Andaman",
        place: "3 Places",
        price: "₹22,899",
        type: "5 Days",
        list: [
            {
                icon: Building,
                title: "3 Star Hotels",
            },
            {
                icon: Activity,
                title: "6 Activities",
            },
            {
                icon: Car,
                title: "Transfers",
            },
        ],
        mostbook: [
            {
                title: "Highly Popular",
                color: "#FF4514",
            },
        ],
    },
    {
        name: "2N Port Blair | 2N Havelock",
        picture: Picture4,
        location: "Andaman",
        place: "2 Places",
        price: "₹20,199",
        type: "5 Days",
        list: [
            {
                icon: Building,
                title: "2 Star Hotels",
            },
            {
                icon: Activity,
                title: "7 Activities",
            },
            {
                icon: Car,
                title: "Transfers",
            },
        ],
        mostbook: [
            {
                title: "Highly Popular",
                color: "#FF4514",
            },
        ],
    },
    {
        name: "2N Port Blair | 2N Havelock | 1N Neil",
        picture: Picture5,
        location: "Andaman",
        place: "3 Places",
        price: "₹89,699",
        type: "6 Days",
        list: [
            {
                icon: Building,
                title: "5 Star Hotels",
            },
            {
                icon: Activity,
                title: "10 Activities",
            },
            {
                icon: Car,
                title: "Transfers",
            },
        ],
        mostbook: [
            {
                title: "Highly Popular",
                color: "#FF4514",
            },
        ],
    },
    {
        name: "3N Port Blair | 1N Havelock | 1N Neil",
        picture: Picture6,
        location: "Andaman",
        place: "3 Places",
        price: "₹22,799",
        type: "6 Days",
        list: [
            {
                icon: Building,
                title: "2 Star Hotels",
            },
            {
                icon: Activity,
                title: "10 Activities",
            },
            {
                icon: Car,
                title: "Transfers",
            },
        ],
        mostbook: [
            {
                title: "Highly Popular",
                color: "#FF4514",
            },
        ],
    },
];

const ExtendedData = [...Honeymoon, ...Honeymoon];

const VerticalCard = ({ id }: { id: string }) => {
    const [activeSlide, setActiveSlide] = useState(1);
    const totalSlides = Honeymoon.length;

    const setPagination = (e: TSwiper) => {
        setActiveSlide((e.realIndex % totalSlides) + 1);
    };
    return (
        <section
            className=" vertical-card-section bg-trending-img mb-treding-img  py-10 md:py-20"
            id="honeymoonPackage"
        >
            <div className="vertical-card-container  container px-4 flex flex-col mx-auto gap-6 sm:gap-14">
                <Title title="Trending Offers" />
                <div className="vertical-card-slider">
                    <Swiper
                        spaceBetween={24}
                        slidesPerView={1}
                        navigation={{
                            nextEl: ".pagination-right-packaged-based-" + id,
                            prevEl: ".pagination-left-packaged-based-" + id,
                            enabled: true,
                        }}
                        modules={[Navigation, Autoplay]}
                        autoplay={{
                            delay: 4000,
                            waitForTransition: true,
                        }}
                        loop={true}
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                            },
                            680: {
                                slidesPerView: 2,
                            },
                            767: {
                                slidesPerView: 3,
                            },
                            1279: {
                                slidesPerView: 4,
                            },
                            1535: {
                                slidesPerView: 4,
                            },
                        }}
                        className="vertical-swiper"
                        onSlideChange={setPagination}
                        onSwiper={setPagination}
                    >
                        {Honeymoon.map((h, index) => {
                            
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="vertical-slide"
                                >
                                    <TredingCard h={h} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className="swiper-pagination-wrapper flex items-center justify-end gap-2 mt-4 sm:mt-6">
                        <div className="pagination-progress flex items-center gap-4">
                            <span className="font-semibold">
                                {activeSlide}/{totalSlides}
                            </span>
                            <div className="progress">
                                <div
                                    className="done"
                                    style={{
                                        width: `${
                                            ((activeSlide - 1) % totalSlides) *
                                            (100 / totalSlides)
                                        }%`,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="pagination-arrows flex items-center gap-3 sm:gap-4">
                            <figure
                                className={`pagination-left-packaged-based-${id} flex-center`}
                            >
                                <Image loading="lazy" 
                                    src={PaginationLeft}
                                    alt="Pagination Left"
                                    
                                />
                            </figure>
                            <figure
                                className={`pagination-right-packaged-based-${id} flex-center`}
                            >
                                <Image loading="lazy" 
                                    src={PaginationRight}
                                    alt="Pagination Right"
                                    
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VerticalCard;
